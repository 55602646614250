<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Campaigns"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>

    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Campaigns
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="success"
          @click="addNewCampaign()"
        >
          Add New Campaign
        </v-btn>
      </v-col>
    </v-row>    
    <v-tabs>
      <v-tab
        v-for="campaignYear in campaignYears"
        :key="campaignYear"
      >
        {{ campaignYear }}
      </v-tab>

      <v-tab-item
        v-for="campaignYear in campaignYears"
        :key="campaignYear"
      >
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="itemsForCampaign(campaignYear)"
                :search="search"
                :items-per-page="50"
                :sort-by="['name']"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:item.matchbacks="{ item }">
                  <router-link
                    :to="{ name: 'Campaign Matchback Results', params: { mail_campaign_id: item.id } }"
                  >
                    <v-btn
                      color="primary"
                    >
                      View Matchbacks
                    </v-btn>
                  </router-link>
                </template>
                <template #item.art_due_date="{value}">
                  {{ formatDate(value) }}
                </template>
                <template #item.mail_date="{value}">
                  {{ formatDate(value) }}
                </template>
                <template #item.report_due_date="{value}">
                  {{ formatDate(value) }}
                </template>
                <template #item.start_matchback_date="{value}">
                  {{ formatDate(value) }}
                </template>
                <template #item.end_matchback_date="{value}">
                  {{ formatDate(value) }}
                </template>
                <template v-slot:item.mail_campaign_files="{ item }">
                  <router-link
                    :to="{ name: 'Campaign Files', params: { mail_campaign_id: item.id } }"
                  >
                    {{ item.mail_campaign_files.length }}
                  </router-link>
                </template>
              </v-data-table>

            </v-card>
          </v-col>
        </v-row>    
      </v-tab-item>
    </v-tabs>
          


    <template v-if="showCampaignDialog">
      <v-card>
        <v-card-title>
          {{ cardTitle }}
        </v-card-title>
        <template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              v-model="name"
              :rules="[v => !!v || 'Name is required']"
              label="Name"
              required
            />

            <v-menu
              v-model="mailDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedMailDate"
                  label="Send Date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="mailDate"
                @input="mailDateMenu = false"
              />
            </v-menu>
            <v-menu
              v-model="reportDueDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedReportDueDate"
                  label="Report Due Date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="reportDueDate"
                @input="reportDueDateMenu = false"
              />
            </v-menu>

            <v-menu
              v-model="matchbackStartDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedMatchbackStartDate"
                  label="Matchback Start Date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="matchbackStartDate"
                @input="matchbackStartDateMenu = false"
              />
            </v-menu>

            <v-menu
              v-model="matchbackEndDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedMatchbackEndDate"
                  label="Matchback End Date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="matchbackEndDate"
                @input="matchbackEndDateMenu = false"
              />
            </v-menu>
            <v-combobox
              v-model="campaignType"
              :items="availableCampaignTypes"
              label="Campaign Type"
            />
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="numberMailed"
                  label="How many will be sent?"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="campaignCost"
                  label="What is the campaign cost?"
                />
              </v-col>
            </v-row>            
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="warning"
                  @click="showCampaignDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  :disabled="!valid"
                  @click="createCampaign()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </v-card>
    </template>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import MailCampaignService from '@/services/MailCampaignService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'MailCampaigns',
    components: {
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        id: null,
        name: '',
        mailDate: null,
        proofToClientDueDate: null,
        reportDueDate: null,
        matchbackStartDate: null,
        matchbackEndDate: null,
        mailDateMenu: false,
        reportDueDateMenu: false,
        matchbackStartDateMenu: false,
        matchbackEndDateMenu: false,
        campaignType: 'Mailer',
        availableCampaignTypes: ['Mailer', 'Email'],
        dialog: false,
        items: [],
        search: '',
        loading: false,
        error: '',
        valid: true,
        cardTitle: null,
        showCampaignDialog: false,
        numberMailed: 0,
        campaignCost: 0,        
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Send Date', value: 'mail_date', align: 'center' },
          { text: 'Report Due', value: 'report_due_date', align: 'center' },
          { text: 'Matchback Start Date', value: 'start_matchback_date', align: 'center' },
          { text: 'Matchback End Date', value: 'end_matchback_date', align: 'center' },
          { text: 'Campaign Type', value: 'campaign_type', align: 'center' },
          { text: 'Campaign Files', value: 'mail_campaign_files', align: 'center' },
          { text: 'Matchbacks', value: 'matchbacks', sortable: false, align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ], 
        campaignYears: [], 
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      formattedMailDate () {
        return this.formatDate(this.mailDate)
      },
      formattedReportDueDate () {
        return this.formatDate(this.reportDueDate)
      },
      formattedMatchbackStartDate () {
        return this.formatDate(this.matchbackStartDate)
      },
      formattedMatchbackEndDate () {
        return this.formatDate(this.matchbackEndDate)
      },
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadCampaigns()
      },
      mailDate () {
        this.setDates()
      },
      campaignType () {
        this.setDates()
      },
    },
    created () {
      this.loadCampaigns()
    },
    methods: {
      itemsForCampaign(campaignYear) {
        return this.items.filter(i => new Date(i.mail_date).getFullYear() === campaignYear)
      },
      pdfLink (mailCampaignId) {
        return 'https://dealer-insights-api.herokuapp.com/mail_campaigns/' + mailCampaignId + '/download_campaign_report?aid=' + this.accountId
      },
      setDates: function () {
        if (this.campaignType === 'Email') {
          this.reportDueDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 48)).toISOString().substring(0, 10)
          this.matchbackStartDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 1)).toISOString().substring(0, 10)
          this.matchbackEndDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 46)).toISOString().substring(0, 10)
        } else {
          this.reportDueDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 49)).toISOString().substring(0, 10)
          this.matchbackStartDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 2)).toISOString().substring(0, 10)
          this.matchbackEndDate = new Date(new Date(this.mailDate).setDate(new Date(this.mailDate).getDate() + 47)).toISOString().substring(0, 10)
        }
      },
      loadCampaigns: function () {
        this.items = []
        this.loading = true
        MailCampaignService.getMailCampaigns()
          .then(response => {
            this.items = response.data
            this.campaignYears = [... new Set(this.items.map(i => new Date(i.mail_date).getFullYear()))].sort().reverse()
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      addNewCampaign: function () {
        this.cardTitle = 'Create Campaign'
        this.name = null
        this.id = null
        this.valid = false
        this.showCampaignDialog = true
      },

      createCampaign: function () {
        this.loading = true
        var data = {
          name: this.name,
          mailDate: this.mailDate,
          reportDueDate: this.reportDueDate,
          matchbackStartDate: this.matchbackStartDate,
          matchbackEndDate: this.matchbackEndDate,
          campaignType: this.campaignType,
          numberMailed: this.numberMailed,
          campaignCost: this.campaignCost,          
        }
        var func = null
        if (this.id) { func = MailCampaignService.updateMailCampaign(data, this.id) } else {
          func = MailCampaignService.createMailCampaign(data)
        }
        func
          .then(response => {
            this.loading = false
            this.showCampaignDialog = false
            this.loadCampaigns()
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Campaign?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          MailCampaignService.deleteMailCampaign(item.id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
      editItem (item) {
        this.cardTitle = 'Edit Campaign'
        this.id = item.id
        this.name = item.name
        this.mailDate = item.mail_date
        this.reportDueDate = item.report_due_date
        this.matchbackStartDate = item.start_matchback_date
        this.matchbackEndDate = item.end_matchback_date
        this.campaignType = item.campaign_type
        this.numberMailed = item.number_mailed
        this.campaignCost = item.campaign_cost
        this.showCampaignDialog = true
      },
    },
  }
</script>
